<template>
  <div class="disputes-summary">
    !! NOT IMPLEMENTED !!
    <!-- <promise-summary /> -->

  </div>
</template>

<script>

export default {
  components: {
    // 'promise-summary': () => import('../account/summary/promise/promise-summary')

  },
  activated () {
    this.$store.commit('setModule', { name: 'Disputes' })
  }
}
</script>
<style lang="stylus" scoped>
.disputes-summary
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap
  padding 0.5em
  align-content flex-start

.disputes-summary>*
  margin 0.5em
</style>
